<template>
<div class="home">
        <div class="features">
            <h4 style="color:#ffff;">{{$t('Validación')}}</h4>
            <h5 style="color:#ffff;">{{mensaje}}</h5>

            <div class="p-grid" >
                <div class="p-col-12 p-md-12">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">{{$t('Póliza')}}</div>
							<div class="p-fluid p-formgrid p-grid" style="text-align: left;">
								<div class="p-field p-col-12 p-md-6">
									<strong>{{$t('Insurance Cover Note #')}} </strong>
                                    <br>{{codigo}}
								</div>
								<div class="p-field p-col-12 p-md-6">
									<strong>{{$t('Nombre Completo')}}: </strong>{{nombre}}
								</div>
								<div class="p-field p-col-12 p-md-4">
									<strong>{{$t('Fecha de Expiración')}}: </strong>{{fecha}}
								</div>
								<div class="p-field p-col-12 p-md-8">
									<strong>{{$t('Estatus')}}:
                                        <div v-if="estado==0">
                                            {{$t('Anulada')}}
                                        </div>
                                        <div v-if="estado==6">
                                            {{$t('Cancelada')}}
                                        </div>
                                        <div v-else>
                                            <div v-if="vend<=0">
                                                {{$t('Vencida')}}
                                            </div>
                                            <div v-else-if="estado==6">
                                                {{$t('Cancelada')}}
                                            </div>
                                            <div v-else-if="pendiente>0">
                                                {{$t('Deuda Vencida')}} <br> ({{$t('Suspendida')}})
                                            </div>
                                            <div v-else-if="proceso>0">
                                                {{$t('Pago Vencido')}} <br> ({{$t('Suspendida')}})
                                            </div>
                                            <div v-else>
                                                {{$t('Activa')}}
                                            </div>
                                        </div>
                                    </strong>
								</div>
                                <div class="p-field p-col-12 p-md-12">
                                    {{nota}}
                                </div>
							</div>
                        </div>
                    </div>
                </div>




			</div>
        </div>
</div>


</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
//import url from "@/service/_URL";

export default {
    data () {
        return {
			cod: null,
            mensaje: null,
            codigo: null,
            fecha: null,
            nombre: null,
            estado_nombre: null,
            estado_name: null,
            estado: null,
            pendiente: null,
            proceso: null,
            vend: null,
            nota: null
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.cod = this.$route.params.cod;	
		this.Mostrar(this.cod);		
		
	},
    methods: {
        Mostrar(cod){
			this.$store.commit('Loading');
			this.poliza = [];
            const Consulta = new API('Query');
			Consulta.Procesar('Validacion',{
				caso: 	'Validacion',
				cod: cod,
				}).then(response => {
					//this.$store.state.error = response;
                    if (response.result){
                        //this.nota = response.result.mensaje;
                        this.codigo = response.result.poliza.codigo;
                        this.nombre = response.result.poliza.nombre;
                        this.fecha = this.convfech(response.result.poliza.vence);
                        this.estado_nombre = response.result.poliza.estado_nombre;
                        this.estado_name = response.result.poliza.estado_name;
                        this.estado = response.result.poliza.estado;
                        this.pendiente = response.result.poliza.pendiente;
                        this.proceso = response.result.poliza.proceso;
                        this.vend = response.result.poliza.vend;
                    } else {
                        this.mensaje = 'NO';
                    }
                    //this.mensaje = response;	
                    this.$store.commit('Loading');
                });
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},

    }
}
</script>